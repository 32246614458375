$(document).ready(function () {
	//Hide element when smaller than 1025
	try {
		drawSVG();
	} catch (error) {}
	setBackground();
	tabActive();
	swiperInit();
	toggleFunc();
	filterDisplay();
	backToTop();
	bodyBanner();
	iframeRatio();
});

Fancybox.bind("[data-fancybox]", {
	parentEl: document.forms[0], // Element containing main structure
});

function drawSVG() {
	try {
		const showTextFill = () => {
			$('.svg-text-fill').addClass('complete');
			setTimeout(() => {
				$('.loading-sreen').addClass('close');
			}, 1000);
		};
		new Vivus(
			'my-svg',
			{
				type: 'delayed',
				duration: 250,
				animTimingFunction: Vivus.EASE,
			},
			showTextFill
		);
	} catch (error) {}
}


function bodyBanner() {
	if ($(".working-sector-detail").length) {
		$("body").addClass("has-banner");
	}
}
function backToTop() {
	let btn = $(".back-to-top");
	btn.on("click", function (e) {
		e.preventDefault();
		$("html, body").animate(
			{
				scrollTop: 0,
			},
			"300"
		);
	});
}

function filterDisplay() {
	$(".filter-table-wrap").each(function () {
		let x = 10;
		$(this).find("tbody tr").slice(0, 10).show();
		let size = $(this).find("tbody tr").length;
		if (size < x || size == x) {
			$(this).find(".ajax-btn-wrap").addClass("disble-btn");
		}
		$(".filter-table-wrap")
			.find(".btn-secondary")
			.on("click", function (e) {
				e.preventDefault();
				x = x + 6;
				$(this)
					.parents(".filter-table-wrap")
					.find("tbody tr")
					.slice(0, x)
					.slideDown();
				if (x >= size || size < x) {
					$(this).parent().addClass("disble-btn");
				}
			});
	});
}

function toggleFunc() {
	var $hamburger = $(".hamburger");
	$(".main-menu-toggle").on("click", function () {
		$(".nav-primary-menu").toggleClass("open-menu");
		$hamburger.toggleClass("is-active");
	});
	if ($(window).width() < 1025) {
		$(".drop-down .title em").on("click", function () {
			if ($(this).parents(".drop-down").hasClass("is-open")) {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
			} else {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
				$(this).parent().next().slideDown();
				$(this).parents(".drop-down").addClass("is-open");
			}
		});
	}
}

function swiperInit() {
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		observer: true,
		observeParents: true,
		preventInteractionOnTransition: true,
		speed: 1205,
		autoplay: false,
		lazy: {
			loadPrevNext: true,
		},
		loop: true,
		navigation: {
			nextEl: ".primary-banner .nav-arrow-next",
			prevEl: ".primary-banner .nav-arrow-prev",
		},
		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "progressbar",
		},
	});

	let timer = null;

	primarySwiper.on("slideChangeTransitionEnd", function () {
		if (timer) {
			clearTimeout(timer); //cancel the previous timer.
			timer = null;
		}
		const currentSlide = primarySwiper.slides[primarySwiper.activeIndex];
		const currentSlideHasVideo = currentSlide.querySelector("video");
		if (currentSlideHasVideo) {
			const videoElement = currentSlide.querySelector("video");
			videoElement.play();
			videoElement.addEventListener("ended", function () {
				primarySwiper.slideToClosest();
			});
		} else {
			timer = setTimeout(() => {
				primarySwiper.slideNext();
			}, 3000);
		}
	});

	const primaryBannerVideo = document.querySelectorAll(
		".primary-banner-video"
	);
	primaryBannerVideo.forEach(function (videoElement) {
		videoElement.addEventListener("ended", function () {
			primarySwiper.slideNext();
		});
		videoElement.addEventListener("click", function (event) {
			if (this.paused) {
				this.play();
				this.parentNode.classList.remove("pause");
			} else {
				this.pause();
				this.parentNode.classList.add("pause");
			}
		});
	});

	var topBanner = new Swiper(".top-banner .swiper", {
		// Optional parameters
		speed: 1205,
		lazy: {
			loadPrevNext: true,
		},
		loop: true,
		slidesPerView: 1,
		autoplay: {
			delay: 5000,
			pauseOnMouseEnter: true,
		},
	});
	var homePartner = new Swiper(".home-swiper-partner .swiper", {
		// Optional parameters
		speed: 1205,
		lazy: {
			loadPrevNext: true,
		},
		loop: true,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			460: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 5,
			},
			1024.1: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 6,
			},
		},
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		navigation: {
			nextEl: ".home-swiper-partner  .nav-arrow-next",
			prevEl: ".home-swiper-partner  .nav-arrow-prev",
		},
	});

	var projectThumb = new Swiper(".project-swiper-thumb  .swiper", {
		spaceBetween: 18,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 3,
				direction: "horizontal",
			},
			420: {
				slidesPerView: 4,
				direction: "horizontal",
			},
			768: {
				slidesPerView: 5,
				direction: "horizontal",
			},
			1024.1: {
				slidesPerView: 4,
				direction: "vertical",
			},
			1440: {
				slidesPerView: 4,
				direction: "vertical",
			},
		},
		navigation: {
			nextEl: ".project-swiper-thumb .nav-next",
			prevEl: ".project-swiper-thumb .nav-prev",
		},
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var projectMain = new Swiper(".project-swiper-main .swiper", {
		slidesPerView: 1,
		centeredSlides: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
		},
		speed: 750,
		loop: false,
		thumbs: {
			swiper: projectThumb,
		},
	});

	$(".home-project-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("project-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-project-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-project-" + index);

		var swiper = new Swiper(".project-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 4000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: true,
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				1200: {
					slidesPerView: 1.35,
				},
			},
			navigation: {
				nextEl: ".btn-next-project-" + index,
				prevEl: ".btn-prev-project-" + index,
			},
		});
	});

	$(".single-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("single-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-single-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-single-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-instance-" + index);

		var swiper = new Swiper(".single-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			slidesPerView: 1,
			navigation: {
				nextEl: ".btn-next-single-" + index,
				prevEl: ".btn-prev-single-" + index,
			},
			pagination: {
				el: ".pagination-instance-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});
	$(".trinity-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("tri-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-tri-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-tri-" + index);

		var swiper = new Swiper(".tri-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".btn-next-tri-" + index,
				prevEl: ".btn-prev-tri-" + index,
			},
		});
	});
	$(".home-trinity-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("tri-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-tri-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-tri-" + index);

		var swiper = new Swiper(".tri-instance-" + index, {
			speed: 1205,
			lazy: {
				loadPrevNext: true,
			},
			loop: true,
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".btn-next-tri-" + index,
				prevEl: ".btn-prev-tri-" + index,
			},
		});
	});

	$(".four-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-four" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-four" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 750,
			observer: true,
			spaceBetween: 20,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1280: {
					slidesPerView: 4,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});
	$(".double-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-quad" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-quad" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			loop: false,
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});
}

function iframeRatio() {
	if ($("body").hasClass("news-detail-page")) {
		if ($(".news-detail .fullcontent iframe").length) {
			$(".news-detail .fullcontent iframe").wrap(
				"<div class='iframe-ratio'></div>"
			);
		}
	}
}

//Tab active

function tabActive() {
	$(".tab-navigation li a").on("click", function () {
		$(this).parents(".tab-navigation").find("li").removeClass("active");
		$(this).parents("li").addClass("active");

		var display = $(this).attr("data-type");
		$(this).parents("section").find(".tab-item").removeClass("active");
		$("#" + display).addClass("active");
	});
}
// Side
function setBackground() {
	$("[setBackground]").each(function () {
		var background = $(this).attr("setBackground");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-size": "cover",
			"background-position": "center center",
		});
	});
	$("[setBackgroundRepeat]").each(function () {
		var background = $(this).attr("setBackgroundRepeat");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-repeat": "repeat",
		});
	});
}
$(document).ready(function () {
	jQuery(function ($) {
		"use strict";
		var counterUp = window.counterUp["default"]; // import counterUp from "counterup2"
		var $counters = $(".counter");
		/* Start counting, do this on DOM ready or with Waypoints. */
		$counters.each(function (ignore, counter) {
			var waypoint = new Waypoint({
				element: $(this),
				handler: function () {
					counterUp(counter, {
						duration: 2000,
						delay: 200,
					});
					this.destroy();
				},
				offset: "top-in-view",
			});
		});
	});
});
